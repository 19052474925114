// Gatsby supports TypeScript natively!
import React, { useEffect, useState } from "react"
import mixpanel from "mixpanel-browser"
import { PageProps, navigate, navigateTo } from "gatsby"
import { Icon, InlineIcon } from "@iconify/react"
import bxPlus from "@iconify/icons-bx/bx-plus"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  getIngredients,
  storeIngredients,
  addIngredient,
} from "../utils/storage"
import { FrideIngredient } from "../types"
import ExampleFridge from "../components/exampleFridge"
import { commonFridge, veganFridge } from "../utils/fridgeContentPreMade"

const Button = () => {
  return <button>Add Ingredients</button>
}

const IngredientItem = ({ ingData }) => {
  return (
    <li className="inline-block bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-700 m-0 w-full">
      <span className="font-semibold">{ingData.name}</span>
      {/*<span className="font-light">{ingData.quantity}</span>*/}
      <span className="float-right"> X </span>
    </li>
  )
}

const AddIngredientsList = ({ ingredients }) => {
  useEffect(() => {}, [ingredients.length])
  if (!ingredients || ingredients.length === 0) {
    return (
      <div
        className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-2 py-1"
        role="alert"
      >
        <p>Search for the ingredients you want to add.</p>
      </div>
    )
  }
  return (
    <ul className="w-full mx-auto lg:w-1/2 shadow-md r-2 divide-y divide-gray-400 rounded">
      {ingredients.map((ing, i) => (
        <IngredientItem ingData={ing} key={ing.name + i} />
      ))}
    </ul>
  )
}

const SearchForIngredient = ({ addItem, list }) => {
  const [name, setName] = useState("")
  const [quantity, setQuantity] = useState(0)
  return (
    <form
      onSubmit={e => e.preventDefault()}
      className="flex items-center border-b border-b-2 border-bgBlue py-2 w-full"
    >
      <input
        type="search"
        placeholder="ingredient name"
        value={name}
        onChange={e => setName(e.target.value)}
        className="w-2/4 appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
      />
      <input
        type="number"
        value={quantity}
        onChange={e => setQuantity(parseInt(e.target.value))}
        className="hidden w-1/4 flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded"
      />
      <button
        className="bg-bgGreen rounded"
        onClick={() => {
          if (name.length === 0) {
            return
          }
          addItem([...list, { name, quantity }])
          addIngredient({ name, quantity })
          // reset list after
          setName("")
          setQuantity(0)
          mixpanel.track(`Added Ingredient: ${name}`)
        }}
      >
        <Icon icon={bxPlus} style={{ fontSize: "30px", color: "white" }} />
      </button>
    </form>
  )
}

/**
 * TODO: ADD form validation and exit protection (if user navigates away without submitting)
 */
const SearchIngredients = (props: PageProps) => {
  // Will hold all the added ingredients that will be pushed to "db"
  const [listItems, addListitem] = useState<FrideIngredient[]>([])
  return (
    <Layout>
      <SEO title="My Recipe Suggestions" />
      <AddIngredientsList ingredients={listItems} />
      <SearchForIngredient addItem={addListitem} list={listItems} />
      <button
        className="text-white mr-2 bg-bgBlue hover:bg-blue-600 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        onClick={() => navigateTo("/recipe-suggestions/")}
      >
        Recipe Suggestions
      </button>
      <div className="py-10">
        <h2>Example Additions</h2>
        <div className="grid grid-cols-2">
          <ExampleFridge
            name={"Basic"}
            ingredients={commonFridge}
            addToList={addListitem}
          />
          <ExampleFridge
            name={"Vegan"}
            ingredients={veganFridge}
            addToList={addListitem}
          />
        </div>
      </div>
    </Layout>
  )
}

export default SearchIngredients
