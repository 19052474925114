import React, { useEffect, useState } from "react"
import mixpanel from "mixpanel-browser"
import { Icon, InlineIcon } from "@iconify/react"
import fridgeIcon from "@iconify/icons-mdi/fridge"
import eyeFilled from "@iconify/icons-ant-design/eye-filled"
import eyeInvisibleFilled from "@iconify/icons-ant-design/eye-invisible-filled"

const IngList = ({ ingredients }) => {
  return (
    <ul>
      {ingredients.map(ing => (
        <li className="text-sm font-semibold text-gray-700" key="ing.name">
          {ing.name}
        </li>
      ))}
    </ul>
  )
}

const ExampleFridge = ({ name, ingredients, addToList }) => {
  const [expandedView, setView] = useState(false)
  const [ingList, setIngList] = useState(ingredients)
  const i = expandedView ? 3 : 1
  //mixpanel.track(`View ingredients: ${expandedView}`)
  return (
    <div className={`flex row-span-${i}`}>
      {!expandedView ? (
        <ul className="m-0">
          <span className="inline px-3 py-1 text-sm font-semibold text-gray-700">
            {name}
          </span>
          <Icon
            icon={fridgeIcon}
            style={{ color: "#4a5568", fontSize: "60px" }}
          />
        </ul>
      ) : (
        <IngList ingredients={ingredients} />
      )}
      <div className="">
        <button
          className=""
          onClick={() => (expandedView ? setView(false) : setView(true))}
        >
          <Icon icon={eyeFilled} style={{ fontSize: "30px" }} />
        </button>
        <button
          className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700"
          onClick={() => {
            addToList(ingList)
            mixpanel.track(`Added Example Fridge: ${name}`)
          }}
        >
          Add to list
        </button>
      </div>
    </div>
  )
}

export default ExampleFridge
