import { FrideIngredient } from "../types"

export const veganFridge: FrideIngredient[] = [
  { name: "hummus" },
  { name: "beans" },
  { name: "tofu" },
  { name: "nuts" },
]

export const commonFridge: FrideIngredient[] = [
  { name: "milk" },
  { name: "butter" },
  { name: "eggs" },
  { name: "celery" },
  { name: "heavy cream" },
  { name: "flour" },
  { name: "olive oil" },
]
